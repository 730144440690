import jsPDF from "jspdf";
import logo from "./../../images/cdl-pharma.png";
import { addLogoToPdf } from "./utils/PdfUtils";

export const printCdlTracking = (order, shipment, study, centerNumber) => {
  const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
  const logoHeight = addLogoToPdf(pdf);
  const pageWidth = pdf.internal.pageSize.getWidth();

  const pageCenter = pdf.internal.pageSize.getWidth() / 2;
  pdf.setFontSize(12);
  const text =
    "Shipping file of sampling kits, specific materials and documents";
  const textHeight = 12;
  const y = logoHeight + 30;
  const leftMargin = 10;
  const leftColumn = 60;
  const rectWidth = pageWidth - leftMargin * 2;
  const rightColumn = pageWidth / 2 + 50;

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  }

  const formattedPreparationDate = formatDate(shipment.preparationDate);
  const formattedSendingDate = formatDate(shipment.sendingDate);
  const formattedCarrierOrderDate = formatDate(shipment.carrierOrderDate);
  const formattedOrderDate = formatDate(order.orderDate);
  const formattedDeliveryDate = shipment.deliveryDate
    ? formatDate(shipment.deliveryDate)
    : null;
  const formattedAorDate = shipment.confirmedDeliveryDate
    ? formatDate(shipment.confirmedDeliveryDate)
    : null;

  pdf.setFillColor(173, 216, 230); // Set fill color to light blue
  pdf.rect(leftMargin, y - textHeight, rectWidth, textHeight, "F"); // Draw a filled rectangle behind the text

  const textY = y - textHeight + pdf.internal.getLineHeight() / 2;
  pdf.text(text.toUpperCase(), pageCenter, textY, { align: "center" });

  pdf.setFontSize(10);
  const lineHeight = 7; // Adjusted line height for more spacing

  // First row: Study code + Sponsor name
  pdf.text(
    `${study?.sponsorStudyCode} (${study?.sponsor?.name})`,
    pageCenter,
    logoHeight + 35,
    { align: "center" }
  );

  // Second row: CDL Study Code
  const cdlStudyCodeText = `CDL Study Code: ${study.cdlStudyCode}`;
  pdf.text(cdlStudyCodeText, pageCenter, logoHeight + 35 + lineHeight, {
    align: "center",
  });

  // Center Number row
  pdf.text(`Center Number:`, leftMargin, logoHeight + 35 + 2 * lineHeight);
  pdf.text(centerNumber, leftColumn, logoHeight + 35 + 2 * lineHeight);

  // Order Number row
  pdf.text(`Order Number:`, leftMargin, logoHeight + 35 + 3 * lineHeight);
  pdf.text(order?.orderNumber, leftColumn, logoHeight + 35 + 3 * lineHeight);

  // Client Order Date row
  pdf.text(`Client Order Date:`, leftMargin, logoHeight + 35 + 4 * lineHeight);
  pdf.text(formattedOrderDate, leftColumn, logoHeight + 35 + 4 * lineHeight);

  // Recipient row
  const recipient = order?.contactName || "Unknown";
  pdf.text(`Recipient:`, leftMargin, logoHeight + 35 + 5 * lineHeight);
  pdf.text(recipient, leftColumn, logoHeight + 35 + 5 * lineHeight);

  // Country row
  const addressParts = order.shippingAddress.split(",");
  const country = addressParts[addressParts.length - 1].trim();
  pdf.text(`Country:`, leftMargin, logoHeight + 35 + 6 * lineHeight);
  pdf.text(country, leftColumn, logoHeight + 35 + 6 * lineHeight);

  const tableData = order?.Supplies?.map((supply) => {
    const materialName = supply.Material.name;
    const shippingQuantity =
      shipment?.productions?.filter(
        (production) => production.materialId === supply.Material.id
      ).length || 0;

    const productionsWithSameMaterial =
      shipment?.productions?.filter(
        (production) => production.materialId === supply.Material.id
      ) || [];
    const selectedProductions = productionsWithSameMaterial
      .map(
        (production) => `(${production.batchNumber}) ${production.reference}`
      )
      .join(", ");

    return {
      materialName,
      shippingQuantity,
      selectedProductions,
    };
  });

  pdf.autoTable({
    head: [["Material", "Sent Quantity", "Selected Productions"]],
    body: tableData.map((item) => [
      item.materialName,
      item.shippingQuantity,
      item.selectedProductions,
    ]),
    startY: 92,
    margin: { left: 10, right: 10 },
    columnStyles: {
      0: { cellWidth: 80, overflow: "linebreak" },
      1: { cellWidth: 18, overflow: "linebreak" },
      2: { cellWidth: 92, overflow: "linebreak" },
    },
    didDrawCell: function (data) {
      const cell = data.cell;
      const pdf = data.doc;

      // Draw vertical lines
      if (data.section === "body" || data.section === "head") {
        pdf.line(
          cell.x + cell.width,
          cell.y,
          cell.x + cell.width,
          cell.y + cell.height
        );

        // Draw left border for the first column
        if (data.column.index === 0 || 1 || 2) {
          pdf.line(cell.x, cell.y, cell.x, cell.y + cell.height);
        }

        // Draw bottom border for the last row
        if (data.row.index === data.table.body.length - 1) {
          pdf.line(
            cell.x,
            cell.y + cell.height,
            cell.x + cell.width,
            cell.y + cell.height
          );
        }
      }
    },
  });

  // Get the y-coordinate of the bottom of the table
  let currentY = pdf.autoTable.previous.finalY + 10;

  // PACKAGES
  const packageDetails = shipment.packageDetails.match(
  /pkg \d+: \d+ l, \d+ h, \d+ w, \d+(\.\d+)? vw/g
  );
  // Convert into an array of objects with length, height, width, and volumetric weight
  const packages = packageDetails?.map((detail) => {
    const parts = detail.split(" ");
    return {
      length: parseInt(parts[2]),
      height: parseInt(parts[4]),
      width: parseInt(parts[6]),
      volumetricWeight: parseFloat(parts[8]),
    };
  });
  // Define the headers for the packages table
  const packageHeaders = [
    ["Package", "Length", "Height", "Width", "Volumetric Weight"],
  ];

  // Map the packages data to the table rows
  const packageRows = packages?.map((pkg, index) => [
    `Package ${index + 1}`,
    `${pkg.length || ""} cm`,
    `${pkg.height || ""} cm`,
    `${pkg.width || ""} cm`,
    `${pkg.volumetricWeight || ""} kg`,
  ]);

  // Add the packages table to the PDF
  pdf.autoTable({
    head: packageHeaders,
    body: packageRows,
    startY: currentY - 3, // Start after the previous table
    margin: { left: 10, right: 10 },
    columnStyles: {
      0: { cellWidth: 50, overflow: "linebreak" },
      1: { cellWidth: 30, overflow: "linebreak" },
      2: { cellWidth: 30, overflow: "linebreak" },
      3: { cellWidth: 30, overflow: "linebreak" },
      4: { cellWidth: 50, overflow: "linebreak" },
    },
  });

  currentY = pdf.autoTable.previous.finalY + 10;

  const checkboxSize = 3;

  // Row 1: Preparation date and Email to recipient
  pdf.text(`Preparation date:`, 10, currentY);
  pdf.text(formattedPreparationDate, leftColumn, currentY);
  pdf.text("Email to recipient:", 120, currentY);
  pdf.rect(rightColumn, currentY - checkboxSize, checkboxSize, checkboxSize); // Checkbox 'Yes'
  pdf.text("Yes", rightColumn + 5, currentY);
  pdf.rect(
    rightColumn + 20,
    currentY - checkboxSize,
    checkboxSize,
    checkboxSize
  ); // Checkbox 'No'
  pdf.text("No", rightColumn + 25, currentY);

  currentY += lineHeight;

  // Row 2: Carrier order date and Email to client
  pdf.text(`Carrier order date:`, 10, currentY);
  pdf.text(formattedCarrierOrderDate, leftColumn, currentY);
  pdf.text("Email to client:", 120, currentY);
  pdf.rect(rightColumn, currentY - checkboxSize, checkboxSize, checkboxSize); // Checkbox 'Yes'
  pdf.text("Yes", rightColumn + 5, currentY);
  pdf.rect(
    rightColumn + 20,
    currentY - checkboxSize,
    checkboxSize,
    checkboxSize
  ); // Checkbox 'No'
  pdf.text("No", rightColumn + 25, currentY);

  currentY += lineHeight;

  // Row 3: Sending date and Date of delivery
  pdf.text(`Sending date:`, 10, currentY);
  pdf.text(formattedSendingDate, leftColumn, currentY);
  pdf.text("Date of delivery:", 120, currentY);
  pdf.text(
    formattedDeliveryDate != null ? formattedDeliveryDate : "",
    rightColumn,
    currentY
  );

  currentY += lineHeight;

  // Row 4: Shipped by and Date of AOR
  const shippedByName = `${shipment.shippedBy.firstname} ${shipment.shippedBy.name}`;
  pdf.text(`Shipped by:`, 10, currentY);
  pdf.text(shippedByName, leftColumn, currentY);
  pdf.text("Date of AOR:", 120, currentY);
  pdf.text(
    formattedAorDate != null ? formattedAorDate : "",
    rightColumn,
    currentY
  );

  currentY += lineHeight;

  // Row 5: Carrier name
  pdf.text(`Carrier name:`, 10, currentY);
  pdf.text(shipment.carrier, leftColumn, currentY);

  currentY += lineHeight;

  // Row 6: Airwaybill
  pdf.text(`Airwaybill:`, 10, currentY);
  pdf.text(shipment.packingSlip, leftColumn, currentY);

  currentY += lineHeight;

  // Row 7: First supply
  pdf.text("First supply:", 10, currentY);
  pdf.rect(leftColumn, currentY - checkboxSize, checkboxSize, checkboxSize); // Draw the checkbox

  if (order.isFirstSupply) {
    // Draw an "X" inside the checkbox
    pdf.line(
      leftColumn,
      currentY - checkboxSize,
      leftColumn + checkboxSize,
      currentY
    );
    pdf.line(
      leftColumn + checkboxSize,
      currentY - checkboxSize,
      leftColumn,
      currentY
    );
  }

  pdf.text("Yes", leftColumn + 5, currentY);

  currentY += lineHeight;
  pdf.save(`cdltracking_${order.orderNumber}.pdf`);
};

export const printPackingList = (shipment, study, order, centerNumber) => {
  const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
  const logoHeight = addLogoToPdf(pdf);
  const pageWidth = pdf.internal.pageSize.getWidth();

  const pageCenter = pdf.internal.pageSize.getWidth() / 2;
  pdf.setFontSize(12);
  const text = "Packing List";
  const textHeight = 12;
  const y = logoHeight + 30;
  const leftMargin = 10;
  const rectWidth = pageWidth - leftMargin * 2;

  pdf.setFillColor(173, 216, 230); // Set fill color to light blue
  pdf.rect(leftMargin, y - textHeight, rectWidth, textHeight, "F"); // Draw a filled rectangle behind the text

  const textY = y - textHeight + pdf.internal.getLineHeight() / 2;
  pdf.text(text.toUpperCase(), pageCenter, textY, { align: "center" });
  const sendingDate = new Date(shipment.sendingDate);
  const formattedDate = `${sendingDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(sendingDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${sendingDate.getFullYear()}`;

  // Frame dimensions
  const frameX = pageCenter - 50;
  const frameY = logoHeight + 32;
  const frameWidth = 100;
  const frameHeight = 28;
  // Set the stroke color to black
  pdf.setDrawColor(0, 0, 0); // RGB color

  // Draw the frame
  pdf.rect(frameX, frameY, frameWidth, frameHeight).stroke();

  pdf.setFontSize(12);
  pdf.text(
    `${study.sponsorStudyCode} (${study.sponsor.name})`,
    pageCenter,
    logoHeight + 40,
    {
      align: "center",
    }
  );
  pdf.text(
    `CDL study code: ${study.cdlStudyCode}`,
    pageCenter,
    logoHeight + 45,
    {
      align: "center",
    }
  );
  // Set the font to bold
  pdf.setFont("helvetica", "bold");

  // Draw the text
  pdf.text(
    `Order ${order?.orderNumber} - ${formattedDate} `,
    pageCenter,
    logoHeight + 50,
    { align: "center" }
  );

  pdf.setFont("helvetica", "normal");
  pdf.text(`Center ${centerNumber}`, pageCenter, logoHeight + 55, {
    align: "center",
  });

  const tableData = order.Supplies
    ? order.Supplies.map((supply) => {
        const materialName = supply.Material.name;
        const shippingQuantity =
          shipment?.productions?.filter(
            (production) => production.materialId === supply.Material.id
          ).length || 0;

        const productionsWithSameMaterial =
          shipment?.productions?.filter(
            (production) => production.materialId === supply.Material.id
          ) || [];
        const selectedProductions = productionsWithSameMaterial
          .map(
            (production) =>
              `(${production.batchNumber}) ${production.reference}`
          )
          .join(", ");

        return {
          materialName,
          shippingQuantity,
          selectedProductions,
        };
      })
    : [];

  pdf.autoTable({
    head: [["Material", "Sent Quantity", "Selected Productions"]],
    body: tableData.map((item) => [
      item.materialName,
      item.shippingQuantity,
      item.selectedProductions,
    ]),
    startY: 72,
    margin: { left: 10, right: 10 },
    columnStyles: {
      0: { cellWidth: 80, overflow: "linebreak" },
      1: { cellWidth: 18, overflow: "linebreak" },
      2: { cellWidth: 92, overflow: "linebreak" },
    },
    didDrawCell: function (data) {
      const cell = data.cell;
      const pdf = data.doc;

      // Draw vertical lines
      if (data.section === "body" || data.section === "head") {
        pdf.line(
          cell.x + cell.width,
          cell.y,
          cell.x + cell.width,
          cell.y + cell.height
        );

        // Draw left border for the first column
        if (data.column.index === 0 || 1 || 2) {
          pdf.line(cell.x, cell.y, cell.x, cell.y + cell.height);
        }

        // Draw bottom border for the last row
        if (data.row.index === data.table.body.length - 1) {
          pdf.line(
            cell.x,
            cell.y + cell.height,
            cell.x + cell.width,
            cell.y + cell.height
          );
        }
      }
    },
  });

  pdf.save(`PackingList_${order.orderNumber}.pdf`);
};

export const printShippingLabel = (order, shipment) => {
  const doc = new jsPDF();

  const width = 50;
  const aspectRatio = 310 / 63; // image dimensions
  const height = width / aspectRatio;

  // Parameters: ImageData, Format, X, Y, Width, Height
  doc.addImage(logo, "PNG", 10, 10, width, height);

  // Sender
  doc.setFontSize(14);
  doc.text("SENDER:", 15, 40);

  // Draw underline for "SENDER:"
  doc.line(15, 42, 90, 42);

  doc.setFontSize(12);
  doc.text("CDL PHARMA ", 15, 50);
  doc.text("34 RUE PEYSSONNEL", 15, 55);
  doc.text("13003 MARSEILLE", 15, 60);
  doc.text("FRANCE", 15, 65);

  const senderNameY = 75;

  const senderName = `Name: ${shipment.shippedBy.firstname.toUpperCase()} ${shipment.shippedBy.name.toUpperCase()}`;
  const senderEmail = `Email: ${shipment.shippedBy.email}`;
  const senderNameLines = doc.splitTextToSize(senderName, 80);
  const senderEmailLines = doc.splitTextToSize(senderEmail, 80);

  doc.text(senderNameLines, 15, senderNameY);
  doc.text(
    "Phone: +33 04 96 20 96 25",
    15,
    senderNameY + senderNameLines.length * 5
  );
  doc.text(senderEmailLines, 15, senderNameY + senderNameLines.length * 5 + 5);

  // Calculate sender block height
  const senderBlockHeight =
    60 + senderNameLines.length * 5 + senderEmailLines.length * 5;

  // Recipient
  doc.setFontSize(14);
  doc.text("RECIPIENT:", 100, 40);

  // Draw underline for "RECIPIENT:"
  doc.line(100, 42, 195, 42);

  doc.setFontSize(12);

  const addressLines = [
    `${order.hospital}`, // Hospital
    `${order.street}`, // Street
    order.ward ? `Ward: ${order.ward}` : "", // Ward (optional)
    order.building && order.floor
      ? `Building: ${order.building} / Floor: ${order.floor}`
      : order.building
      ? `Building: ${order.building}`
      : order.floor
      ? `Floor: ${order.floor}`
      : "", // Building and Floor (optional)
    `${order.zipcode} ${order.shippingAddress.split(",")[0]}`, // Zipcode + City
    `${order.shippingAddress.split(",")[1].trim()}`, // Country
    "", // Empty line to skip a row
    `${order.contactName ? `Name: ${order.contactName.toUpperCase()}` : ""}`, // Contact Name
    `Phone: ${order.contactPhone}`, // Contact Phone
    `Email: ${order.contactEmail}`, // Contact Email
  ];
  const filteredAddressLines = addressLines.filter((line) => line);

  let currentY = 50;
  let recipientBlockHeight = 0;
  filteredAddressLines.forEach((line, index) => {
    const isContactInfo =
      line.startsWith("Name:") ||
      line.startsWith("Phone:") ||
      line.startsWith("Email:");
    const maxWidth = 70; // max width for the text, if over it will wrap
    const textLines = doc.splitTextToSize(line, maxWidth); // Split text
    textLines.forEach((textLine) => {
      doc.text(
        isContactInfo ? textLine : textLine.toUpperCase(),
        100,
        currentY
      );
      currentY += 5;
      recipientBlockHeight += 5;
    });
    // Add extra line height after the country
    if (line === `${order.shippingAddress.split(",")[1].trim()}`) {
      currentY += 5;
      recipientBlockHeight += 5;
    }
  });

  // Calculate the maximum height between sender and recipient blocks
  const maxHeight = Math.max(senderBlockHeight, recipientBlockHeight + 20);

  // Draw frame around the sender block with the calculated height
  doc.rect(10, 30, 85, maxHeight); // x, y, width, height

  // Draw frame around the recipient block with the calculated height
  doc.rect(96, 30, 105, maxHeight); // x, y, width, height

  doc.save("ShippingLabel.pdf"); // save the PDF
};

export const printAorForm = (order, shipment, centerNumber, study) => {
  const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
  const logoHeight = addLogoToPdf(pdf);
  const pageWidth = pdf.internal.pageSize.getWidth();

  const pageCenter = pdf.internal.pageSize.getWidth() / 2;
  pdf.setFontSize(12);
  const text = "ACKNOWLEDGEMENT OF RECEIPT FORM";
  const leftMargin = 10;
  const textHeight = 12;
  const y = logoHeight + 30;
  const leftColumn = 50;
  const headerWidth = pageWidth - leftMargin * 2;

  pdf.setFillColor(173, 216, 230);
  pdf.rect(leftMargin, y - textHeight, headerWidth, textHeight, "F");

  const textY = y - textHeight + pdf.internal.getLineHeight() / 2;
  pdf.text(text, pageCenter, textY, { align: "center" });

  pdf.setFontSize(10);

  const addressParts = order.shippingAddress.split(", ");
  const city = addressParts[0];
  const country = addressParts[1];
  const hospital = order.hospital;

  const lineHeight = 7;

  // Study row
  pdf.text(
    `${study.sponsorStudyCode} (${study.sponsor.name})`,
    pageCenter,
    logoHeight + 35,
    { align: "center" }
  );

  // CDL Study Code row
  const cdlStudyCodeText = `CDL Study Code: ${study.cdlStudyCode}`;
  pdf.text(cdlStudyCodeText, pageCenter, logoHeight + 35 + lineHeight, {
    align: "center",
  });

  // Order Number row
  pdf.text(`Order Number:`, leftMargin, logoHeight + 35 + 2 * lineHeight);
  pdf.text(order.orderNumber, leftColumn, logoHeight + 35 + 2 * lineHeight);

  // Center Number row
  pdf.text(`Center Number:`, leftMargin, logoHeight + 35 + 3 * lineHeight);
  pdf.text(centerNumber, leftColumn, logoHeight + 35 + 3 * lineHeight);

  // Hospital row
  pdf.text(`Hospital:`, leftMargin, logoHeight + 35 + 4 * lineHeight);
  pdf.text(hospital, leftColumn, logoHeight + 35 + 4 * lineHeight);

  // City row
  pdf.text("City:", leftMargin, logoHeight + 35 + 5 * lineHeight);
  pdf.text(city, leftColumn, logoHeight + 35 + 5 * lineHeight);

  // Country row
  pdf.text(`Country:`, leftMargin, logoHeight + 35 + 6 * lineHeight);
  pdf.text(country, leftColumn, logoHeight + 35 + 6 * lineHeight);
  // Define the rectangle's position and dimensions
  const rectX = leftMargin;
  const rectY = logoHeight + 80;
  const rectWidth = pdf.internal.pageSize.getWidth() - 2 * leftMargin;
  const rectHeight = 25;

  // Draw the rectangle
  pdf.rect(rectX, rectY, rectWidth, rectHeight);

  // Add the title
  const title = "Instructions:";
  pdf.text(title, rectX + 5, rectY + 5);

  // Define the instructions text
  const instructions = [
    "After reception of material and/or documents please :",
    "- check the presence and the quantity received",
    "- complete this form",
    "- send this form to CDL Pharma by email to order@cdlpharma.com",
  ].join("\n");

  // Split the instructions text into multiple lines
  const splitInstructions = pdf.splitTextToSize(instructions, rectWidth - 10);

  // Add the split text to the PDF
  pdf.text(splitInstructions, rectX + 5, rectY + 10);

  const tableData = order.Supplies
    ? order.Supplies.map((supply) => {
        const materialName = supply.Material.name;
        const shippingQuantity =
          shipment?.productions?.filter(
            (production) => production.materialId === supply.Material.id
          ).length || 0;

        return {
          materialName,
          shippingQuantity,
          receivedQuantity: "",
          materialConform: "", // checkbox is drawn separately
        };
      })
    : [];

  pdf.autoTable({
    head: [
      [
        "Material Name",
        "Sent Quantity",
        "Received Quantity",
        "Material Conform?",
      ],
    ],
    body: tableData.map((item) => [
      item.materialName,
      item.shippingQuantity,
      item.receivedQuantity,
      item.materialConform,
    ]),
    startY: 120,
    margin: { left: 10, right: 10 },
    tableWidth: "auto", // Set the table width to auto
    columnStyles: {
      0: { cellWidth: 85 }, // Set the width of the first column
      1: { cellWidth: 30 }, // Set the width of the second column
      2: { cellWidth: 40 }, // Set the width of the third column
      3: { cellWidth: 35 }, // Set the width of the fourth column
    },
    didDrawCell: function (data) {
      const cell = data.cell;
      const pdf = data.doc;

      // Draw checkboxes
      if (data.section === "body" && data.column.index === 3) {
        const checkboxSize = 4;
        const x = cell.x + cell.width / 2 - checkboxSize / 2;
        const y = cell.y + cell.height / 2 - checkboxSize / 2;

        // Set the line width to make the checkbox lines thicker
        pdf.setLineWidth(0.5);

        // Draw the rectangle for the checkbox
        pdf.rect(x, y, checkboxSize, checkboxSize);

        // Reset the line width to default after drawing the checkbox
        pdf.setLineWidth(0.2);
      }

      // Draw vertical lines
      if (data.section === "body" || data.section === "head") {
        pdf.line(
          cell.x + cell.width,
          cell.y,
          cell.x + cell.width,
          cell.y + cell.height
        );

        // Draw left border for the first column
        if (data.column.index === 0) {
          pdf.line(cell.x, cell.y, cell.x, cell.y + cell.height);
        }

        // Draw border between "Material Name" and "Sent Quantity"
        if (data.column.index === 1) {
          pdf.line(cell.x, cell.y, cell.x, cell.y + cell.height);
        }

        // Draw left border for the third column
        if (data.column.index === 2) {
          pdf.line(cell.x, cell.y, cell.x, cell.y + cell.height);
        }

        // Draw bottom border for the last row
        if (data.row.index === data.table.body.length - 1) {
          pdf.line(
            cell.x,
            cell.y + cell.height,
            cell.x + cell.width,
            cell.y + cell.height
          );
        }
      }
    },
  });

  // Get the y-coordinate of the bottom of the table
  const tableBottomY = pdf.autoTable.previous.finalY;

  // Define the position and dimensions of the comment section
  const commentTitleY = tableBottomY + 10;
  const commentSectionX = leftMargin;
  const commentSectionY = commentTitleY - 5; // Adjusted to include the title
  const commentSectionWidth = pdf.internal.pageSize.getWidth() - 2 * leftMargin;
  const commentSectionHeight = 30; // Adjusted height to include the title

  // Add the title
  const commentTitle = "COMMENTS:";
  const textWidth = pdf.getTextWidth(commentTitle);
  const commentTitleX = pageCenter - textWidth / 2;
  pdf.text(commentTitle, commentTitleX, commentTitleY);

  // Draw the comment section
  pdf.rect(
    commentSectionX,
    commentSectionY,
    commentSectionWidth,
    commentSectionHeight
  );

  // Add the "PERSON IN CHARGE OF RECEPTION" text
  const receptionText = "PERSON IN CHARGE OF RECEPTION:";
  const receptionTextWidth = pdf.getTextWidth(receptionText);
  const receptionTextX = pageCenter - receptionTextWidth / 2;
  const receptionTextY = commentSectionY + commentSectionHeight + 10;
  pdf.text(receptionText, receptionTextX, receptionTextY);

  // Define the position of the input fields
  const inputFieldY = receptionTextY + 10;
  const interspace = 10; // space between rows

  pdf.text("Name:", 15, inputFieldY);
  pdf.text("Date:", 120, inputFieldY);

  pdf.text("Email: ", 15, inputFieldY + interspace);
  pdf.text("Signature:", 120, inputFieldY + interspace);

  pdf.text("Phone:", 15, inputFieldY + 2 * interspace);

  // Calculate the height of the "PERSON IN CHARGE OF RECEPTION" block
  const receptionBlockHeight =
    inputFieldY + 2 * interspace + 10 - receptionTextY;

  // Draw the rectangle around the "PERSON IN CHARGE OF RECEPTION" block
  pdf.rect(
    commentSectionX,
    receptionTextY - 5,
    commentSectionWidth,
    receptionBlockHeight + 4
  );
  pdf.save("AorForm.pdf");
};
